'use strict';

var _paperRipple = require('paper-ripple');

var _paperRipple2 = _interopRequireDefault(_paperRipple);

var _initSwiper = require('./init-swiper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

if (!!window.MSInputMethodContext && !!document.documentMode) {
  $('html').addClass('ie11');
  // .forEach and Promise polyfill
  $('html head').prepend('<script src="https://polyfill.io/v3/polyfill.min.js?features=default%2CNodeList.prototype.forEach%2CArray.prototype.forEach%2CPromise%2CPromise.prototype.finally"></script>');
}

window.onload = function () {
  document.querySelector('body').classList.add('is-loaded');
  var $preloader = document.querySelectorAll('.ff-preloader');
  $preloader.forEach(function (el) {
    el.classList.add('is-hiding');
  });
  setTimeout(function () {
    $preloader.forEach(function (el) {
      el.classList.remove('is-hiding');
    });
    $preloader.forEach(function (el) {
      el.classList.add('is-hidden');
    });
  }, 300);
  $('.main-container').addClass('active');
};
jQuery(document).ready(function ($) {

  new _initSwiper.InitSwiper();

  $('.dropdown-menu').on({
    'click': function click(e) {
      e.stopPropagation();
    }
  });

  $(document).on('click', '.hamburger', function () {
    $('.mobile-nav').addClass('active');
  });
  $(document).on('click', '.mobile-nav .close', function () {
    $('.mobile-nav').removeClass('active');
  });

  $('.wpcf7-checkbox .checkbox, .wpcf7-acceptance .checkbox').each(function () {
    var _this = $(this);
    _this.addClass('custom-control custom-checkbox').find('label').addClass('custom-control-label').attr('id', $('input', _this).attr('id')).find('input').addClass('custom-control-input').insertBefore(_this.find('label'));
  });
  $('.wpcf7-radio .radio').each(function (i) {
    var _this = $(this);
    _this.addClass('custom-control custom-radio').find('label').addClass('custom-control-label').attr('for', $('input', _this).attr('name') + i).find('input').addClass('custom-control-input').attr('id', $('input', _this).attr('name') + i).insertBefore(_this.find('label'));
  });

  // scroll parallax
  $(window).scroll(function () {
    var top = $(window).scrollTop();
    $('#header-image').css({
      backgroundPositionY: top / 1.5 - 100 + 'px'
    });

    if (top > 0) {
      $('#header').addClass('is-stuck');
    } else {
      $('#header').removeClass('is-stuck');
    }
  });

  // ink ripple buttons
  // $('header nav ul a, .btn, .ink, [type="submit"]').each(function(i, button) {
  // 	const ripple = new PaperRipple()
  // 	button.classList.add('inked')
  // 	button.appendChild(ripple.$)
  // 	button.addEventListener('mousedown', function(ev) {
  // 		ripple.downAction(ev)
  // 	})
  // 	button.addEventListener('mouseup', function() {
  // 		ripple.upAction()
  // 	})
  // 	button.addEventListener('blur', function() {
  // 		ripple.upAction()
  // 	})
  // })

  $('header nav li.dropdown').on('show.bs.dropdown', function (e) {

    // $(this).find('.dropdown-menu a').velocity('transition.slideDownIn', {
    //   stagger: 25,
    //   duration: 200
    // })

  });
});