'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitSwiper = InitSwiper;

var _swiper = require('swiper');

var _swiper2 = _interopRequireDefault(_swiper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function InitSwiper() {

  var ffSwiper2 = new _swiper2.default('.ff-slider-swiper2');

  // ff slider
  if (ffSwiper) ffSwiper.destroy(true, true);

  var ffSwiper = new _swiper2.default('.ff-slider-swiper', {
    speed: 1500,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      waitForTransition: true
    },
    autoHeight: true,
    parallax: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });

  // restart timeline after slide change
  // ffSwiper.on('slideChangeTransitionEnd transitionEnd', function() {
  // 	const elm = $('.ff-slider-timeline .progress').get(0)
  // 	const newone = elm.cloneNode(true)
  // 	elm.parentNode.replaceChild(newone, elm)
  // })
  // // pause on touchStart
  // ffSwiper.on('touchStart', function() {
  // 	$('.ff-slider-timeline .progress').addClass('paused')
  // })
  // // resume on touchEnd
  // ffSwiper.on('touchEnd', function() {
  // 	$('.ff-slider-timeline .progress').removeClass('paused')
  // })
}